import { FC } from 'react';
import { SectionBase } from '@outside-interactive/web-microfrontends.03-organisms.section';
import { MediaBlock } from '@outside-interactive/web-microfrontends.02-molecules.media-block';
import { PageProps } from '@outside-interactive/web-microfrontends.helpers.types';
import {
  H1,
  H2,
  H3,
  H4
} from '@outside-interactive/web-microfrontends.01-atoms.heading';
import { LearnCarousel } from '@outside-interactive/web-microfrontends.02-molecules.carousel';
import { CourseCard } from '@outside-interactive/web-microfrontends.02-molecules.card';
import { useRouter } from 'next/router';
import Head from 'next/head';
import { HeroBody } from '@outside-interactive/web-microfrontends.02-molecules.hero-body';
import { routes } from 'lib/routes';
import { GET_LEARN_HOMEPAGE } from 'queries';
import { CourseCarouselItem, Homepage } from 'types';
import OutsideLearnLogoHoriz from 'components/01-atoms/OutsideLearnLogoHoriz';
import { NextSeo } from 'next-seo';
import {
  VIDEO_PLAYER_ID,
  PLACEHOLDER_IMAGE,
  FASTLY_PARAMS,
  APPLICATION_URL,
  Z_FOREGROUND
} from 'lib/constants';
import { useTailwindBreakpoint } from '@outside-interactive/web-microfrontends.helpers.use-tailwind-breakpoint';
import {
  useUserType,
  joinOutside
} from 'components/04-templates/HeadlessProvider';
import { useAnalyticsContext } from 'components/04-templates/AnalyticsProvider';
import { parsePageSeo } from 'utils';
import { HomepageData } from 'types/PageTypes';
import { initializeApollo } from 'helpers/hooks';

const Home: FC<PageProps<HomepageData>> = ({
  courseBlock,
  promotionalSection = [],
  footerCta,
  hero,
  image,
  seo
}) => {
  const { isScreenNarrowerThan } = useTailwindBreakpoint();
  const isMobile = isScreenNarrowerThan('narrow-x');
  const { isOutsidePlusUser } = useUserType();
  const { elementClicked } = useAnalyticsContext();

  const handleCTAClick = () => {
    elementClicked({ name: 'homepage-hero-cta' });
    joinOutside();
  };

  // If call /signup/outsideplus AND not already logged in O+, which is mapped to this page, then automatically open up joinOutside()
  const { asPath } = useRouter();

  if (asPath.startsWith('/signup/outsideplus') && !isOutsidePlusUser) {
    joinOutside();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const schemaData: any = [
    {
      '@context': 'https://schema.org/',
      '@type': 'WebPage',
      '@id': `${APPLICATION_URL}${asPath.split('?')[0]}`
    }
  ];

  if (courseBlock) {
    schemaData.push({
      '@context': 'https://schema.org/',
      '@type': 'ItemList',
      name: 'Featured Courses',
      numberOfItems: courseBlock?.courses?.length,
      itemListElement: courseBlock?.courses?.map((course, index) => ({
        '@type': 'Course',
        position: index + 1,
        url: `${APPLICATION_URL}${routes.courseIntroduction(course.name)}`,
        name: course?.title,
        description: course?.excerpt,
        isAccessibleForFree: 'False',
        provider: {
          '@type': 'Organization',
          name: 'Outside',
          url: 'https://www.outsideonline.com/'
        }
      }))
    });
  }

  return (
    <>
      {seo && <NextSeo {...parsePageSeo({ ...seo, asPath })} />}
      <Head>
        <script
          type="application/ld+json"
          id="learn-home-schema"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: JSON.stringify(schemaData) }}
          key="learn-home-schema"
        />
      </Head>
      <section className={isMobile ? 'relative' : 'desktop-video-hero-wrapper'}>
        <div className={isMobile ? 'relative' : 'desktop-video-hero'}>
          <HeroBody
            imageSrc={image}
            imageAlt="Outside learn hero image"
            button={
              !isOutsidePlusUser
                ? {
                    onClick: handleCTAClick,
                    text: hero.buttonText,
                    target: '_blank',
                    classes: 'px-16 pt-4 pb-3 mt-5 font-bold btn-primary'
                  }
                : null
            }
            title={<OutsideLearnLogoHoriz />}
            description={hero.subhead}
            disclaimer={!isOutsidePlusUser ? hero.disclaimer : null}
            videoConfig={
              hero.video
                ? {
                    playerId: VIDEO_PLAYER_ID,
                    isMuted: true,
                    isAutoPlay: true,
                    playerScript: `https://cdn.jwplayer.com/libraries/${VIDEO_PLAYER_ID}.js`,
                    playlist: `https://content.jwplatform.com/feeds/${hero.video}.json`,
                    width: '100%',
                    stretching: 'fill',
                    classes: `preview-video atomic-video z-${Z_FOREGROUND} ${
                      isMobile ? 'aspect-w-16 aspect-h-9 ' : ''
                    }`,
                    controls: true,
                    cast: false,
                    repeat: true
                  }
                : null
            }
            showOverlay
          />
        </div>
      </section>
      <div className="h-full w-full pt-5 narrow-x:pt-12">
        {courseBlock && (
          <SectionBase
            HeadingComponent={
              <H1 headingText={courseBlock.title} classes="h1 pb-2 px-5" />
            }
            SubHeadingComponent={
              <p className="mb-6 p-1 px-5 text-network-grey-darker">
                {courseBlock.subhead}
              </p>
            }
            classes="text-center pb-5 narrow-x:p-10"
          >
            <div>
              <LearnCarousel viewLimit={3} mobileViewLimit={1}>
                {(courseBlock.courses || []).map(
                  (course: CourseCarouselItem) => (
                    <CourseCard
                      key={course.id}
                      imageSrc={
                        course.heroImage
                          ? `${course.heroImage}?crop=16:9&width=450&${FASTLY_PARAMS}`
                          : PLACEHOLDER_IMAGE
                      }
                      imageAlt={course.title}
                      heading={course.title}
                      description={course.excerpt}
                      href={routes.courseIntroduction(course.name)}
                      // TODO - display multiple instructors, if needed
                      instructor={course.instructors?.[0]?.name}
                      hoverOverlayText="Go to Course"
                      shouldLazyLoad={false}
                    />
                  )
                )}
              </LearnCarousel>
            </div>
          </SectionBase>
        )}

        <div className="mx-5 wide:mx-15 wide-x:mx-30">
          {promotionalSection.map((pSection, index) => (
            <SectionBase
              key={pSection.header}
              HeadingComponent={
                <H2
                  headingText={pSection.header}
                  classes="h2 text-network-font-1 py-5 wide:py-10"
                />
              }
              classes="text-center pb-10 wide:p-10"
            >
              <MediaBlock
                classes="items-center wide-x:items-start"
                textConfig={{
                  headerComponent: (
                    <H3
                      className="h4 wide-x:pt-5"
                      headingText={pSection.title}
                    />
                  ),
                  richText: pSection.content,
                  linkConfig: pSection.buttonText
                    ? {
                        text: pSection.buttonText,
                        href: pSection.buttonLink,
                        target: '_blank',
                        className:
                          'border-solid border border-black rounded-md shadow-sm uppercase py-1 px-6 font-semibold text-sm tracking-wider w-full narrow-x:w-auto block narrow-x:inline text-center'
                      }
                    : null,
                  classes: index % 2 ? 'wide:pl-5' : 'wide:pr-5'
                }}
                imagePriority
                alignImage={index % 2 ? 'left' : 'right'}
                imageConfig={{
                  alt: pSection.title,
                  src: isMobile
                    ? `${pSection.image}?crop=600:340&width=600&${FASTLY_PARAMS}`
                    : `${pSection.image}?crop=16:9&width=750&${FASTLY_PARAMS}`
                }}
              />
            </SectionBase>
          ))}
        </div>

        {footerCta && !isOutsidePlusUser && (
          <>
            <SectionBase
              HeadingComponent={
                <H4
                  headingText={footerCta.title}
                  classes="h2 pb-2 px-5 wide:pt-15"
                />
              }
              SubHeadingComponent={
                <p className="u-text--p-std m-auto mb-6 max-w-5xl  p-1 px-5 text-center text-network-grey-darker">
                  {footerCta.subhead}
                </p>
              }
              linkConfig={{
                onClick: handleCTAClick,
                text: footerCta.buttonText,
                target: '_blank',
                className:
                  'w-3/4 narrow-x:w-1/4 pt-4 pb-3 mt-5 text-center font-bold tracking-widest uppercase rounded mx-auto btn-primary'
              }}
              classes="text-center"
            />
            <p className="u-text--p-sm mb-6 py-1 text-center text-network-grey-darker">
              {hero.disclaimer}
            </p>
          </>
        )}
      </div>
    </>
  );
};

export async function getStaticProps() {
  const client = initializeApollo();
  const { data, error } = await client.query<Homepage>({
    query: GET_LEARN_HOMEPAGE
  });

  if (error) {
    throw new Error(
      `Could not build static page for Learn homepage. Errors: ${JSON.stringify(
        error.message
      )}`
    );
  }
  const { props } = data;
  return { props, revalidate: 300 };
}

export default Home;
